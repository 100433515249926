import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import test from "../../Assets/Projects/test.gif"
import P1 from "../../Assets/Projects/P1.gif"
import P2 from "../../Assets/Projects/P2.png"
import P3 from "../../Assets/Projects/P3.gif"
import P4 from "../../Assets/Projects/P4.gif"
import P5 from "../../Assets/Projects/P5.gif"
import P6 from "../../Assets/Projects/P6.gif"
import P7 from "../../Assets/Projects/P7.gif"
import P8 from "../../Assets/Projects/P8.gif"
import P9 from "../../Assets/Projects/P9.gif"
import P10 from "../../Assets/Projects/P10.gif"
import P11 from "../../Assets/Projects/P11.gif"
// make a list of titles 

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
              <ProjectCard
                imgPath={P10}
                isBlog={false}
                title="Report Generation Tool"
                description="Developed a report generation tool using Selenium, OpenAI API, Retrieval-Augmented Generation (RAG) and caption genaration. This tool cut report writing time by 70% and significantly improved documentation efficiency."
                // demoLink="https://your-demo-link.com"
                ghLink="https://github.com/yourusername/report-generation-tool"
              />
            </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={P1}
              isBlog={false}
              title="Damage Assessment Framework"
              description="Developed a deep learning framework for post-hurricane structural damage as-
              sessment, leveraging unlabeled data with Vision Transformer (Semi-ViT) and achieved 88% accuracy surpassing both
              human-level assessment and current state-of-the-art benchmarks (funded by NASA)."
              // demoLink="https://www.mdpi.com/1424-8220/23/19/8235"
              ghLink="https://www.mdpi.com/1424-8220/23/19/8235"
            />
          </Col>

          <Col md={4} className="project-card">
  <ProjectCard
    imgPath={P11}
    isBlog={false}
    title="3D Digital Twin of Navy Ships"
    description="Developed a 3D digital twin of underwater navy ships using Structure from Motion (SFM) and applied a deep learning model for color correction to improve the under water texture."
    ghLink=""
    // demoLink="https://your-demo-link.com"
  />
</Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={P2}
              isBlog={false}
              title="Corrosion Detection Model"
              description="Trained a multi-class Corrosion Detection Model based on Transformer (segformer ). Data
              collection via UAV, data annotation, and training were conducted on a Navy ship (USS Paul F Foster). Achieved 71%
              accuracy and 60% mean Intersection over Union (mIoU) across three corrosion levels."
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={P3}
              isBlog={false}
              title="Digital Twin & VR App"
              description="Developed a 3D twin for above & underwater structures using SFM. Applied deep learning
              for underwater image correction & post-reconstruction damage detection. Created a VR
              Simulation for Windows & Meta Quest 2) for visual inspections on the Navy Ship using
              Unreal Engine 5 and Unity."
              ghLink=""
              // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={P9}
              isBlog={false}
              title="CEGSO Website Development"
              description="Developed a website for the Civil Engineering Graduate Student Organization (CEGSO) at the University of Houston. The website includes features such as events information, contact us, member directory, and resource sharing to enhance communication and collaboration among students."
              // demoLink="https://cegso.uh.edu"
              ghLink="https://github.com/yourusername/cegso-website"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={P4}
              isBlog={false}
              title="Flood Recovery over US"
              description="Developed a ML model to estimate flood recovery times across the United States, introducing a novel 'recoveriness' index. Using ML models, I identified six flash flood recovery hotspots and conducted a comprehensive seasonality analysis. The study extended from gauged basins to a high-resolution grid covering the entire conterminous US."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              // demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={P5}
              isBlog={false}
              title="Charachterization of Granular Particles"
              description="I developed a computational method for precise quantification of particle shape features in granular materials, emphasizing sphericity, roundness, and roughness. Using a robust MATLAB algorithm and image analysis, the method was validated against established visual charts, demonstrating its effectiveness on diverse real soil particles."
              // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={P6}
              isBlog={false}
              title="Quick FEM Surrogate Model"
              description="Developed an ML model predicting stress and deformation on a 3D cantilever beam to expedite product development by reducing prototyping needs. Leveraging FEM, Ansys, and Python automation, this approach accelerates the analysis workflow, offering a faster and more efficient alternative to traditional methods."
              ghLink="https://github.com/deepankkumar/Quick-FEM-Surrogate-Model"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={P7}
              isBlog={false}
              title="Residential Apartment Design & Analysis"
              description="Designed a G+6 multistorey residential apartment at MNIT Jaipur using AutoCAD for drafting and STAAD Pro for analysis and design. Column locations were defined as nodes in an Excel sheet, facilitating efficient transfer to STAAD Pro. The entire project encompassed design, planning, and structural analysis."
              // ghLink="https://github.com/deepankkumar/Quick-FEM-Surrogate-Model"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={P8}
              isBlog={false}
              title="Stress Analysis of Steel Silo"
              description="The objective of this study was to analyze the stress sensitivity of a steel silo over a range of plate thicknesses. Employing computational FEA using staad.pro-v8i to understand the stress variation revealed that the maximum absolute stresses for each plate were observed at the midpoint of the vertical section of the silo."
              // ghLink="https://github.com/deepankkumar/Quick-FEM-Surrogate-Model"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;