// Techstack.js

import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Techstack.css"; // Import your CSS file for styling

function Techstack() {
  const skills = [
    { name: "STAAD.pro", category: "Structural" },
    { name: "SAP2000", category: "Structural" },
    { name: "ANSYS", category: "Structural" },
    { name: "ABAQUS", category: "Structural" },
    { name: "AutoCAD", category: "Structural" },
    { name: "ArcGIS Pro", category: "GIS" },
    { name: "QGIS", category: "GIS" },
    { name: "MATLAB", category: "Programming" },
    { name: "Python", category: "Programming" },
    { name: "C/C++", category: "Programming" },
    { name: "Java", category: "Programming" },
    { name: "Machine Learning", category: "Programming" },
    { name: "Deep Learning", category: "Programming" },
    { name: "GenAI", category: "Programming" },
  ];

  return (
    <Row className="skills-box">
      {skills.map((skill, index) => (
        <Col key={index} xs={4} md={2} className={`tech-box ${skill.category}`}>
          <div>
            {skill.name}
          </div>
          <div className="category-text">
            {skill.category}
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Techstack;
