import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi! I'm a Research Assistant at the <span className="purple">Structural and Artificial Intelligence Lab (SAIL)</span>. I earned my bachelor's in Civil Engineering from <span className="purple">MNIT Jaipur</span>, and completed research internships at <span className="purple">IIT Delhi</span> and <span className="purple">IIT (BHU)</span>. Currently pursuing a Ph.D. in <span className="purple">Structural Engineering</span> at the <span className="purple">University of Houston (UH)</span>.
            <br />
            <br />
            Beyond academics, I enjoy:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
            <li className="about-activity">
              <ImPointRight /> Exploring new technologies
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
