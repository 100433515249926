import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ToolsCards from "./ToolsCards";
import Particle from "../Particle";
import T1 from "../../Assets/Tools/T1.png"
import T2 from "../../Assets/Tools/T2.png"
import T3 from "../../Assets/Tools/T3.png"
// make a list of titles 

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Tools </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few tools I've made recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="tools-card">
            <ToolsCards
              imgPath={T2}
              isBlog={false}
              title="M-P-Phi Combined Load Interaction"
              description = "M-P-Phi interaction diagram is used to check the interaction of axial force and moment. This tool is used to plot the M-P-Phi interaction diagram for a given section."
              // demoLink="https://www.mdpi.com/1424-8220/23/19/8235"
              ghLink="https://p-m-phi.streamlit.app/"
            />
          
          </Col>
          <Col md={4} className="tools-card">
            <ToolsCards
                imgPath={T1}
                isBlog={false}
                title="DeepStocks"
                description = "DeepStocks is a web application that allows user to create and analyze stock portfolios. The application compares the performance of the user's portfolio with the S&P 500 index."
                // demoLink="https://www.mdpi.com/1424-8220/23/19/8235"
                ghLink="https://deepstocks.streamlit.app/"
              />
          </Col>

          
          <Col md={4} className="tools-card">
            <ToolsCards
              imgPath={T3}
              isBlog={false}
              title="QuickFEM"
              description = "This project involves developing a machine learning model to predict stress and deformation on a 3D cantilever beam. The goal is to accelerate the product development workflow FEM analysis."
              // demoLink="https://www.mdpi.com/1424-8220/23/19/8235"
              ghLink="https://quickfem.streamlit.app/"
            />
          
          </Col>
          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
