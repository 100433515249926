// About.js

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";
import RelevantCoursework from "./RelevantCoursework"; // Import the RelevantCoursework component
import Publications from "./Publications"; // Import the Publications component
import Certifications from "./Certifications"; // Import the Certifications component

// About.js

function About() {
  return (
    <div className="content">
    <Container fluid className="about-section">
      <Particle className="particle" />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
              zIndex: 2, /* This will place your content in front of the Particle component */
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", zIndex: 2, textAlign: "center"  }}>
              Know Who <strong className="purple">I'm</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px", zIndex: 2 }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <h1 style={{ fontSize: "2.1em", paddingBottom: "20px", zIndex: 2, textAlign: "center"  }}>
          Technical <strong className="purple">Skills </strong>
        </h1>
        <Techstack />
        <h1 style={{ fontSize: "2.1em", zIndex: 2, paddingTop: "20px", paddingBottom: "20px", textAlign: "center"  }}>
            Relevant<strong className="purple"> Coursework</strong>
        </h1>  
        <RelevantCoursework />
        
        <Publications /> 
        <Certifications />
      </Container>
    </Container>
    </div>
  );
}

export default About;