// PublicationsCard.js


import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function PublicationsCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        
        <h1 style={{ fontSize: "2.1em", zIndex: 2, paddingTop: "20px", paddingBottom: "20px", textAlign: "center"  }}>
        <strong className="purple">Publications </strong> and <strong className="purple">Conferences </strong>
      </h1>
      <blockquote className="blockquote mb-0">
     
      <p style={{ textAlign: "justify", fontStyle: "italic" }}>
        1. Singh, D.K.; Hoskere, V. Post Disaster Damage Assessment Using Ultra-High-Resolution Aerial Imagery with Semi-Supervised Transformers. <span style={{ color: "purple", fontWeight: "bold" }}>Sensors </span>2023, 23, 8235. <a href="https://doi.org/10.3390/s23198235" target="_blank" rel="noopener noreferrer">https://doi.org/10.3390/s23198235</a>
      </p>
      <p style={{ textAlign: "justify", fontStyle: "italic" }}>
        2. Singh, D.K.; Hoskere, V. Climate Resilience through AI-Driven Hurricane Damage Assessments. Proceedings of the  <span style={{ color: "purple", fontWeight: "bold" }}>AAAI Conference</span> on Artificial Intelligence 2023 <a href="https://doi.org/10.1609/aaaiss.v2i1.27661" target="_blank" rel="noopener noreferrer">https://doi.org/10.1609/aaaiss.v2i1.27661</a>
      </p>
      <p style={{ textAlign: "justify", fontStyle: "italic" }}>
        3. Singh, D.K. ; Tripathi, U.; Yadav, A.; Shrivastava, S. Utilization of Construction and Demolition Waste in the Development of Bitumen Block as an Alternate Material. <span style={{ color: "purple", fontWeight: "bold" }}>2nd ASCE Conference </span>(CRSIDE2020)
      </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default PublicationsCard;

