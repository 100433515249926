import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { ReactComponent as GoogleScholarIcon } from "./google-scholar.svg"; // Import your Google Scholar SVG

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              Greetings! I am a <i><b className="purple">Structural Engineering Ph.D. student</b></i> at the University of Houston, with my research focused on the integration of <i><b className="purple">artificial intelligence</b></i> into <i><b className="purple">Structural Engineering</b></i>. <br />
              <br />
              I'm Proficient in computational tools such as <i><b className="purple">Python, GenAI, Deep Leaning, FEM packages</b></i>, and armed with a comprehensive understanding of advanced structural engineering concepts. <br />
              <br />
              Holding an <i><b className="purple"> <a href="https://www.credly.com/badges/738deff8-fdb4-433a-a610-d637a469895f" target="_blank" rel="noreferrer">Engineer-in-Training (E.I.T.)</a></b></i> certification further underscores my commitment to professional growth.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" style={{ width: "75%" }} />
            </Tilt>
          </Col>

        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/deepankkumar"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://scholar.google.com/citations?user=your-GlKZZ-kAAAAJ&hl=en" // Replace with your Google Scholar link
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <GoogleScholarIcon />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/dksin/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/depaank"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
