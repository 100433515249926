// Certifications.js

import React from "react";
import Card from "react-bootstrap/Card";
import { Container, Row, Col } from "react-bootstrap";
import "./Certifications.css"; // Import your new CSS file

const certifications = [
  {
    title: "Fundamentals of Engineering (FE) by NCEES",
    link: "https://www.credly.com/badges/738deff8-fdb4-433a-a610-d637a469895f",
  },
  {
    title: "Programming for Everybody by University of Michigan",
    link: "https://www.coursera.org/account/accomplishments/certificate/XYASM27GMPNQ",
  },
  {
    title: "SQL for Data Science by University of California, Davis",
    link: "https://www.coursera.org/account/accomplishments/certificate/5PHBFR26KGAB",
  },
  {
    title: "Convolutional Neural Networks by DeepLearning.AI",
    link: "https://www.coursera.org/account/accomplishments/certificate/9ZRHJML5YTDD",
  },
  {
    title: "Improving Deep Neural Networks by DeepLearning.AI",
    link: "https://www.coursera.org/account/accomplishments/certificate/XUDXHKPPH2FX",
  },
  {
    title: "Machine Learning by Stanford University",
    link: "https://www.coursera.org/account/accomplishments/certificate/LSP8JWCHUX8P",
  },
  {
    title: "Neural Networks and Deep Learning by DeepLearning.AI",
    link: "https://coursera.org/share/1c3a140a9e70257ebb0c2da0230ee29d",
  },
  {
    title: "Algorithm Toolbox by University of California, San Diego",
    link: "https://coursera.org/share/a61e712c8c04d5782024b9cc41c6b734",
  }
];

function Certifications() {
  return (
    <Container>
      <h1 style={{ fontSize: "2.1em", zIndex: 2, paddingTop: "20px", paddingBottom: "20px", textAlign: "center"  }}>
        <strong className="purple">Certifications </strong> and <strong className="purple">Licenses </strong>
      </h1>
      <Row className="certifications-box">
        {certifications.map((certification, idx) => {
          let title = certification.title;
          let by = "";

          if (certification.title.includes(" by ")) {
            [title, by] = certification.title.split(" by ");
            by = " by " + by;
          }

          return (
            <Col key={idx} md={4}>
              <div className="certification-card">
                <h5>{title}</h5>
                <p>{by}</p>
                <a className="certification-link" href={certification.link} target="_blank" rel="noopener noreferrer">
                  Click here
                </a>
                <div className="certification-category">{/* Add category here if needed */}</div>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Certifications;