// Techstack.js
import React from "react";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";
import "./RelevantCoursework.css"; // Import your CSS file for styling

function Techstack() {
  const skills = [
    { name: "Adv. Concrete Design", category: "Design" },
  { name: "Adv. Steel Design", category: "Design" },
  { name: "Adv. Concrete Analysis", category: "Analysis" },
  { name: "Advanced Mechanics", category: "Analysis" },
  { name: "Finite Element Analysis", category: "Analysis" },
  { name: "Structural Dynamics", category: "Analysis" },
  { name: "Structural Health Monitoring", category: "Analysis" },
  { name: "Applied Geospatial Computation", category: "GIS" },
  { name: "Digital Image Meteorology", category: "GIS" },
  { name: "Adv. Machine Learning", category: "Programming" },
  { name: "Deep Learning for Engineering", category: "Programming" },
  { name: "Computer Vision", category: "Programming" },
  ];

  return (
    
      <Row className="skills-box">
        {skills.map((skill, index) => (
          <Col key={index} xs={4} md={2} className={`tech-box ${skill.category}`}>
            <div>
              {skill.name}
            </div>
            <div className="category-text">
              {skill.category}
            </div>
          </Col>
        ))}
      </Row>
      
  );
}

export default Techstack;
