import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home_image.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import { Helmet } from "react-helmet"; // Import Helmet
import "../../App.css"; // Import your CSS file for styling and animations

function Home() {
  return (
    <section>
      <Helmet>
        <meta name="google-site-verification" content="iyBpTceLyb4DKFtwFifPjFdr7DDLrVjSL2KkCOrZMto" />
      </Helmet>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave" >
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'm
                <strong className="main-name"> Deepank Singh </strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid animated-logo" // Add a class for styling and animation
                style={{ maxHeight: "450px" , alignContent: "center"}}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
